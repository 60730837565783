import React from "react";
import { Row, Col } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import CarouselCustom from "../../components/CarouselCustom";

const ThankYou = (props) => {
	window.scroll({
		top: 0,
		left: 0,
		behavior: "auto",
	});
	const { t, i18n } = useTranslation();
	React.useEffect(() => {
		document.title = t("COMMON.TITLE");
	}, [i18n.language]);
	return (
		<>
			<CarouselCustom />
			<div className="thankspage py-5">
				<div className="container">
					<Row className="justify-content-center">
					<Col className="col-sm-9">
					<h1 className="ff-StdHeavy uppercase mb-4">{t("THANK_YOU.HEADING")}!</h1>
					<div className="mb-0 bg-dark-blue h-10"></div>
					<div className="bg-grey pb-5">
							<h2 className="pt-5 pb-4" dangerouslySetInnerHTML={{ __html: t("THANK_YOU.UPLOAD_MSG") }}></h2>
							<p className="text-center ff-StdBold mb-4" dangerouslySetInnerHTML={{ __html: t("THANK_YOU.NOTIFICATION_MESSAGE") }}></p>
						<Row xs={1} sm={1} md={1} lg={3} className="px-3">
							<Col className="text-center py-3">
								<p className="p-small ff-StdMedium" dangerouslySetInnerHTML={{ __html: t("THANK_YOU.RECEIPT_UPLOAD_TEXT") }}></p>
								<a href="/" id="submit-receipt-btn" className="btn btn-primary w-100 btn-thank-you"> {t("THANK_YOU.RECEIPT_UPLOAD_BTN")} </a>
							</Col>
							<Col className="text-center py-3">
								<p className="p-small ff-StdMedium" dangerouslySetInnerHTML={{ __html: t("THANK_YOU.CHECK_STATUS_TEXT") }}></p>
								<a href="/" id="check-status-btn" className="btn btn-primary w-100 btn-thank-you"> {t("THANK_YOU.CHECK_STATUS_BTN")} </a>
							</Col>
							<Col className="text-center py-3">
								<p className="p-small ff-StdMedium" dangerouslySetInnerHTML={{ __html: t("THANK_YOU.PRODUCTS_TEXT") }}></p>
								<a href="https://www.costco.ca/procter-and-gamble-brand-showcase.html" id="participating-products-btn" className="btn btn-primary w-100 btn-thank-you"> {t("THANK_YOU.PRODUCTS_BTN")} </a>
							</Col>
						</Row>
					</div>
					</Col>
					</Row>
				</div>
			</div>
		</>
	);
};

export default ThankYou;
