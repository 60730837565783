import React, { Fragment } from "react";
import { useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import Header from "../layout/Header";
import Footer from "../layout/Footer";
import FooterTerms from "../layout/FooterTerms";

const PublicLayout = ({ children }) => {
  const location = useLocation();
  const user = useSelector((state) => state.user);
  
  const isHomePage = location.pathname === "/";
  const isUploadReceiptPage = location.pathname === "/upload-receipt"; 
  const isNotLoggedIn = !user?.accessToken;

  const shouldHideFooterTerms = (isHomePage) && isNotLoggedIn ||  (isUploadReceiptPage) && !isNotLoggedIn;

  return (
    <Fragment>
      <a className="skiptocontent" href="#main">
        skip to content
      </a>
      <div className="skin-size side-color main-container">
        <Header />
        <main id="main" role="main">
          {children}
        </main>
        {!shouldHideFooterTerms && <FooterTerms />}
        <Footer />
      </div>
    </Fragment>
  );
};

export default PublicLayout;
