import React, { useState } from "react";
import { Card, Modal } from "react-bootstrap";
import { toast } from "react-toastify";
import IsLoadingHOC from "../../components/IsLoadingHOC";
import { connect, useSelector } from "react-redux";
import errorHtml from "../../components/Error";
import { login } from "../../redux/actions";
import { useHistory } from "react-router-dom";
import SocialLogin from "../../components/SocialLogin";
import { Trans, useTranslation } from "react-i18next";
import SecondFactorAuth from "../../components/SecondFactorAuth";
import FingerprintJS from "@fingerprintjs/fingerprintjs-pro";
import Captcha from "../../components/Captcha";
import { TextField } from "@material-ui/core";
const Login = ({ setLoading, login }) => {
	const { t } = useTranslation();
	const history = useHistory();
	const confige = useSelector((state) => state.app.config);
	const [username, setUserName] = useState("");
	const [password, setPassword] = useState("");
	const [showSecondFactorPopup, setShowSecondFactorPopup] = useState(false);
	const [secondFactorAuthData, setSecondFactorAuthData] = useState({});
	const [errors, setErrors] = useState({ username: "", password: "", captchaVal: "" });
	const [fpData, setFpData] = useState({ visitorId: "", requestId: "" });
	let [captchaValue, setCaptchaValue] = useState(false);
	let [captchaEnable, setCaptchaEnable] = useState(false);
	const [resetCaptchaCnt, setResetCaptchaCnt] = useState(0);
	const onChangeHandle = (e) => {
		const { name, value } = e.target;
		if (name === "username") {
			setUserName(e.target.value);
			value.length < 1 ? setErrors({ ...errors, username: t("LOGIN_SCREEN.USERNAME_REQ") }) : setErrors({ ...errors, username: "" });
		}
		if (name === "password") {
			value.length < 1 ? setErrors({ ...errors, password: t("LOGIN_SCREEN.PASS_REQ") }) : setErrors({ ...errors, password: "" });
			setPassword(e.target.value);
		}
	};
	const [redirectUrl, setRedirectUrl] = useState("/");
	React.useEffect(() => {
		if (confige.fingerprinting) {
			if (confige.fingerprinting.enable) {
				const fpPromise = FingerprintJS.load({ apiKey: confige.fingerprinting.clientKey });
				// Get the visitor identifier when you need it.
				fpPromise
					.then((fp) => fp.get())
					.then((result) => {
						setFpData({ visitorId: result.visitorId, requestId: result.requestId });
					});
			}
		}
		if (confige.captchaConfig) {
			if (confige.captchaConfig.loginCaptcha) {
				setCaptchaEnable(true);
			}
		}
	}, [confige]);
	React.useEffect(() => {
		let errorArray = JSON.parse(JSON.stringify(errors));
		if (captchaValue) {
			errorArray["captchaVal"] = "";
		}
		setErrors(errorArray);
		// eslint-disable-next-line
	}, [captchaValue]);
	React.useEffect(() => {
		const query = new URLSearchParams(window.location.search);
		const redtUrl = query.get("route") ? query.get("route") : "/";
		if (redirectUrl !== redtUrl) {
			setRedirectUrl(redtUrl);
		}
		// eslint-disable-next-line
	}, [window.location]);
	const handleError = () => {
		let value = false;
		const error = {};
		if (username.length < 1) {
			error.username = t("LOGIN_SCREEN.USERNAME_REQ");
			value = true;
		}
		if (password.length < 1) {
			error.password = t("LOGIN_SCREEN.PASS_REQ");
			value = true;
		}
		if (password.length < 1) {
			error.password = t("LOGIN_SCREEN.PASS_REQ");
			value = true;
		}
		if (!captchaValue && captchaEnable) {
			error.captchaVal = "COMMON.CAPTCHAERR";
			value = true;
		}
		return { value, error };
	};

	const onSubmit = () => {
		const { value, error } = handleError();
		if (value) {
			setErrors({ ...errors, ...error });
			return false;
		}
		setLoading(true);
		let requestBody = { username, password, visitorId: fpData.visitorId, requestId: fpData.requestId };
		if (captchaEnable) {
			requestBody["captchaVal"] = captchaValue;
		}
		login(requestBody)
			.then((response) => {
				setLoading(false);
				setResetCaptchaCnt(resetCaptchaCnt + 1);
				setCaptchaValue(false);
				if (response.httpCode === 204) {
					setSecondFactorAuthData({ id: response.data });
					setShowSecondFactorPopup(true);
				} else {
					toast.success(t("LOGIN_SCREEN.USER_LOGIN_SUCC"));
					history.push(redirectUrl);
				}
			})
			.catch((error) => {
				console.log("Error in login", error);
				setResetCaptchaCnt(resetCaptchaCnt + 1);
				setCaptchaValue(false);
				if (error?.errors) {
					let err = error?.errors[0];
					if (err["errorCode"]) {
						toast.error(t(`LOGIN_SCREEN.${err["errorCode"]}`));
					} else {
						if (error["httpCode"] === 403) {
							toast.error(t("LOGIN_SCREEN.USER_SUSPEENDED"));
						} else if (error["httpCode"] === 406) {
							toast.error(t("LOGIN_SCREEN.USER_NOT_VERIFIED"));
						} else {
							toast.error(t("LOGIN_SCREEN.E_INVALID_CREDENTIALS"));
						}
					}
				} else {
					toast.error(t("LOGIN_SCREEN.E_INVALID_CREDENTIALS"));
				}
				setLoading(false);
			});
	};

	return (
		<div className="container">
			<div className="row justify-content-center">
				<div className="col-md-10 col-xs-12">
					<form aria-label="login">
						<div className="text-center">
							<h2>{t("lang_key_alreadyRegistered")}</h2>
						</div>
						<div className="form-group row theme-form-email">
							<div className="col">
								<TextField
									InputProps={{
										autoComplete: "true",
									}}
									id={`${t("lang_key_username")}`}
									fullWidth
									label={`${t("lang_key_username")}`}
									data-test="sign-in-username-input"
									name="username"
									onChange={onChangeHandle}
									variant="outlined"
								/>
								<div className="input-error">{errors.username ? t("LOGIN_SCREEN.USERNAME_REQ") : ""}</div>
							</div>
						</div>
						{/*[START:PASSWORD]*/}
						<div className="form-group row theme-form-password">
							<div className="col">
								<TextField
									InputProps={{
										autoComplete: "true",
									}}
									variant="outlined"
									fullWidth
									type="password"
									data-test="sign-in-password-input"
									name="password"
									onChange={onChangeHandle}
									id={t("password")}
									label={t("password")}
									onKeyPress={(e) => e.key === "Enter" && onSubmit()}
								/>
								{/* {errorHtml(errors.password)} */}
								<div className="input-error">{errors.password ? t("LOGIN_SCREEN.PASS_REQ") : ""}</div>
							</div>
						</div>
						<p className="styleColor font-italic mb-2 fs-1em">
							<Trans>{`${t("LOGIN_SCREEN.LOGIN_NOTE")}`}</Trans>
						</p>
						<a className="blue mb-2 d-inline-block fs-1em ff-StdHeavy" href="/forget-password">
							<Trans>{`${t("LOGIN_SCREEN.FORGET_USERNAME_PASS")}`}</Trans>
						</a>
						{/*[END:PASSWORD]*/}
						{/*[START:GOOGLECAPTCHA]*/}
						{captchaEnable ? (
							<div className="form-group row justify-content-center">
								<div className="col">
									{/* <label htmlFor="g-recaptcha-response" className="col-sm-12 col-form-label d-none" aria-hidden="true">
										Google captcha
									</label> */}
									<Captcha align="left" reset={resetCaptchaCnt} parentCallback={setCaptchaValue} />
									{/* {errorHtml(errors.captchaVal)} */}
									<div className="input-error">{t(errors.captchaVal)}</div>
								</div>
							</div>
						) : (
							""
						)}
						{/*[END:GOOGLECAPTCHA]*/}

						<div className="form-group row justify-content-center">
							<div className="col-12">
								<button onClick={onSubmit} data-test="sign-in-sign-in-button" type="button" className="btn btn-primary w-100 mt-0 uppercase">
								<Trans>{`${t("LOGIN_SCREEN.LOGIN_BTN")}`}</Trans>
								</button>
							</div>
						</div>
					</form>
				</div>
			</div>
			<Modal show={showSecondFactorPopup}>
				<Modal.Body>{showSecondFactorPopup ? <SecondFactorAuth otpLen={6} waitingTime={120} data={secondFactorAuthData} /> : ""}</Modal.Body>
			</Modal>
		</div>
	);
};

export default connect(null, { login })(IsLoadingHOC(Login));
